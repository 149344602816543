// import React, { Fragment } from 'react'
import React from 'react'
import classNames from 'classnames'

import Reveal from 'Reveal'

/* eslint-disable */
const TriggerWord = ({ handleClick, word, index }) => (
  <span
    onClick={() => handleClick(index)}
    className='c-strapline-msg__link o-action-link'
  >
    {word.toLowerCase()}
  </span>
)
/* eslint-enable */

const HomepageStraplineMessage = ({ body, keywords, appendage, footnote, handleClick, isVisible }) => {
  return (
    <div className={classNames(null, { 'u-hide-completely': !isVisible })}>
      <Reveal bottom>
        <p className='c-strapline-msg u-dis-i e-h1'>
          {`${body} `}
          {/*
            keywords.map((word, index) => {
              if (index === keywords.length - 1) {
                return (
                  <Fragment key={index}>
                    {'and '}
                    <TriggerWord
                      handleClick={handleClick}
                      word={word}
                      index={index}
                      key={word}
                    />
                    .
                  </Fragment>
                )
              } else if (index === keywords.length - 2) {
                return (
                  <Fragment key={index}>
                    <TriggerWord
                      handleClick={handleClick}
                      word={word}
                      index={index}
                      key={word}
                    />
                    {' '}
                  </Fragment>
                )
              } else {
                return (
                  <Fragment key={index}>
                    <TriggerWord
                      handleClick={handleClick}
                      word={word}
                      index={index}
                      key={word}
                    />
                    {', '}
                  </Fragment>
                )
              }
            })
          */}
          <span
            aria-hidden
            className='c-strapline-msg__appendage'
          >
            {appendage}
          </span>
        </p>
      </Reveal>
      {/* <Reveal bottom>
        <p className='u-color-grey u-mar-t-large'>{footnote}</p>
      </Reveal> */}
    </div>
  )
}

export default HomepageStraplineMessage
