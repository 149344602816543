import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { mergeNodeFields } from 'utils/mergeNodeFields'

import Layout from 'Layout'
import Homepage from 'Homepage'
import PreFooter from 'PreFooter'

const IndexPageTemplate = ({ data }) => {
  const { frontmatter: page } = data.markdownRemark
  const { nodes: featuredProjects } = data.featuredProjects
  const { nodes: allProjects } = data.allProjects

  return (
    <Layout>
      <Homepage
        featuredPosts={mergeNodeFields(featuredProjects)}
        allPosts={mergeNodeFields(allProjects)}
        lazyload
        {...page}
      />
      <PreFooter homepage />
    </Layout>
  )
}

IndexPageTemplate.propTypes = {
  data: PropTypes.object.isRequired
}

export default IndexPageTemplate

export const query = graphql`
  query {
    markdownRemark(
      frontmatter: {
        templateKey: {
          eq: "pages/index"
        }
      }
    ) {
      id
      html
      frontmatter {
        title
        heroFooterText
        postsTitle
        ...StraplineFragment
      }
    }
    ...FeaturedProjectsFragment
    ...AllProjectsFragment
  }
`
