import React from 'react'
import classNames from 'classnames'

import Section from 'Section'

import HomepageStrapline from './HomepageStrapline'
import HomepageHero from './HomepageHero'
import HomepagePosts from './HomepagePosts'

const Homepage = ({ strapline, heroFooterText, postsTitle, featuredPosts, allPosts, className, lazyload }) => (
  <div className={classNames('c-homepage', {
    [className]: className
  })}
  >
    {
      featuredPosts &&
        <HomepageHero
          posts={featuredPosts}
          footerText={heroFooterText}
          lazyload={lazyload}
        />
    }
    <Section id='content'>
      <HomepageStrapline {...strapline} />
    </Section>
    {
      allPosts &&
        <Section>
          <HomepagePosts
            title={postsTitle}
            posts={allPosts}
          />
        </Section>
    }
  </div>
)

export default Homepage
