import React from 'react'
import PropTypes from 'prop-types'
import Swiper from 'react-id-swiper'

import SliderItem from './SliderItem'

const Slider = ({ data, itemClassName, lazyload, ...props }) => (
  <Swiper
    {...props}
  >
    {
      data.map((item, key) =>
        <article
          key={key}
          className={itemClassName}
        >
          <SliderItem
            parentClassName={itemClassName}
            lazyload={lazyload}
            {...item}
          />
        </article>
      )
    }
  </Swiper>
)

Slider.propTypes = {
  data: PropTypes.array.isRequired,
  props: PropTypes.object
}

export default Slider
