import React from 'react'
import classNames from 'classnames'
import { Link } from 'gatsby'

import Reveal from 'Reveal'

import ProjectPreviewImage from './ProjectPreviewImage'

import ArrowSVG from 'assets/img/svg/arrow-right.svg'

const ProjectPreview = ({ meta, slug, client, description, projectTitle, thumbnail }) => (
  <Reveal>
    <article
      className={classNames('c-project-roll-grid-item u-pos-r', {
        'c-project-roll-grid-item--disabled': meta.comingSoon,
        'c-project-roll-grid-item--full-width': meta.isFullWidthOnHomepage
      })}
      key={slug}
    >
      <Link
        to={slug}
        state={{ reveal: true }}
        className={classNames('c-project-roll-grid-item-content u-mar-t-small u-mar-t-none@md c-arrow-link__parent', {
          'c-project-roll-grid-item-content--is-visible': meta.comingSoon
        })}
      >
        <div className='c-project-roll-grid-item-content__header'>
          <h3 className='c-project-roll-grid-item__title e-h2 u-mar-b-none'>{projectTitle}</h3>
          <p className='c-project-roll-grid-item__sub e-h6'>{client}</p>
          <p className='c-project-roll-grid-item__excerpt u-hide-visually-until@md'>{description}</p>
        </div>
        <div className='c-project-roll-grid-item-content__footer u-mar-t u-mar-t-none@md'>
          {
            meta.comingSoon
              ? <span>Coming soon</span>
              : <span className='c-project-roll-grid-item__btn c-arrow-link'>
                <span className='c-arrow-link__text'>
                  {'View project '}
                </span>
                <span className='c-arrow-link__svg'>
                  <ArrowSVG aria-hidden />
                </span>
              </span>
          }
        </div>
      </Link>
      <ProjectPreviewImage
        square={thumbnail.square}
        landscape={thumbnail.fullWidth}
        urlSquare={thumbnail.urlSquare}
        urlLandscape={thumbnail.urlFullWidth}
        isGIF={thumbnail.isGIF}
        isFullWidthOnHomepage={meta.isFullWidthOnHomepage}
        title={projectTitle}
      >
        <Link
          to={slug}
          className='u-pos-a u-pos-cover'
        >
          <span className='u-hide-visually'>{`View ${client}`}</span>
        </Link>
      </ProjectPreviewImage>
    </article>
  </Reveal>
)

export default ProjectPreview
