/* Compress a node (from graphql query) */
export const mergeNodeFields = (nodes) => (
  nodes.map(({ frontmatter, fields, excerpt }) => {
    const node = Object.assign({}, frontmatter, fields)

    /* Check if excerpt exists */
    if (excerpt) {
      node.excerpt = excerpt
    }

    return node
  })
)
