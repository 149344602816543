import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import SliderItemImage from './SliderItemImage'

import ArrowSVG from 'assets/img/svg/arrow-right.svg'

const SliderItem = ({ title, sub, link, images, lazyload, parentClassName, slug }) => (
  <>
    <header className={`${parentClassName}__content c-arrow-link__parent`}>
      <Link
        to={slug}
        state={{ reveal: true }}
      >
        {
          title &&
            <h2 className={`${parentClassName}__title`}>
              {title}
            </h2>
        }
        {
          sub &&
            <p className={`${parentClassName}__sub`}>
              {sub}
            </p>
        }
        <span className='c-arrow-link'>
          <span className='c-arrow-link__text'>
            {'View project '}
          </span>
          <span className='c-arrow-link__svg'>
            <ArrowSVG />
          </span>
        </span>
      </Link>
    </header>
    <SliderItemImage
      image={images.desktop}
      mobileImage={images.mobile}
      url={images.url}
      isGIF={images.isGIF}
      className={`${parentClassName}__img`}
      lazyload={lazyload}
      title={title}
    />
  </>
)

SliderItem.propTypes = {
  title: PropTypes.string,
  sub: PropTypes.string,
  images: PropTypes.object.isRequired,
  slug: PropTypes.string.isRequired
}

export default SliderItem
