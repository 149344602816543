import React, { Component } from 'react'
import { graphql } from 'gatsby'
import classNames from 'classnames'

import Reveal from 'Reveal'
import ArrowLink from 'ArrowLink'

import StraplineMessage from './HomepageStraplineMessage'
// import StraplineTabs from './HomepageStraplineTabs'

let tabHeights = []

class HomepageStrapline extends Component {
  constructor (props) {
    super(props)

    this.state = {
      currentScreen: 'initial',
      selectedTabIndex: 0
    }

    this.handleTabChange = this.handleTabChange.bind(this)
    this.handleInitialTabRender = this.handleInitialTabRender.bind(this)

    /* window.addEventListener('resize', () => {
      this.updateTabHeights()
    }) */
  }

  componentDidUpdate () {
    this.updateTabHeights()
  }

  componentDidMount () {
    this.updateTabHeights()
  }

  updateTabHeights () {
    const tabs = Array.prototype.slice.call(document.querySelectorAll('.c-strapline-tabs__item'))

    tabHeights = []

    tabs.forEach((tab) => {
      tabHeights.push(tab.offsetHeight)
    })

    tabHeights.sort((a, b) => { return b - a })
  }

  /**
   * Control the tab state in this component, allowing
   * us to update the state from other related components
   * e.g.. <Message />
   */
  handleTabChange (tabIndex) {
    this.setState({ selectedTabIndex: tabIndex })
  }

  /* Show the tabs view with the desired tab */
  handleInitialTabRender (tabIndex) {
    this.setState({ currentScreen: 'tabs', selectedTabIndex: tabIndex })
  }

  getArrowTop () {
    if (tabHeights.length > 0) {
      return { top: `${tabHeights[0] + 50}px` }
    }

    return null
  }

  render () {
    const { body, industries, appendage, footnote } = this.props
    const { currentScreen/*, selectedTabIndex*/ } = this.state

    return (
      <div className='c-strapline u-pos-r'>
        <div className='o-container--xs'>
          <StraplineMessage
            body={body}
            keywords={industries.map(({ name }) => name)}
            appendage={appendage}
            footnote={footnote}
            handleClick={this.handleInitialTabRender}
            isVisible={currentScreen === 'initial'}
          />
          {/* <StraplineTabs
            data={industries}
            isVisible={currentScreen === 'tabs'}
            selectedIndex={selectedTabIndex}
            handleTabChange={this.handleTabChange}
          /> */}
          <div
            className={classNames('c-strapline-footer', { 'c-strapline-footer--tabs': (currentScreen === 'tabs') })}
            style={this.getArrowTop()}
          >
            <Reveal bottom>
              <ArrowLink
                to='/about/'
              >
                {'How we work '}
              </ArrowLink>
            </Reveal>
          </div>
        </div>
      </div>
    )
  }
}

export default HomepageStrapline

export const query = graphql`
  fragment StraplineFragment on MarkdownRemarkFrontmatter {
    strapline {
      body
      industries {
        name
        description
      }
      appendage
      footnote
      link {
        text
        url
      }
    }
  }
`
