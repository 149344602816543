import React from 'react'
import { graphql } from 'gatsby'

import Reveal from 'Reveal'
import { HTMLContent } from 'Content'
import ProjectPreview from 'Project/ProjectPreview'

const HomepagePosts = ({ title, posts }) => (
  <div className='c-project-roll'>
    <Reveal bottom>
      <header className='c-project-roll-header u-ta-c'>
        <h2 className='c-project-roll-header__title'>
          <HTMLContent content={title} />
        </h2>
      </header>
    </Reveal>
    <div className='c-project-roll-grid'>
      {
        posts.map(({ ...props }, index) => <ProjectPreview key={index} {...props} />)
      }
    </div>
  </div>
)

export default HomepagePosts

export const query = graphql`
  fragment AllProjectsFragment on Query {
    allProjects: allMarkdownRemark(
      filter: {
        frontmatter: {
          draft: {
            eq: false
          }
          templateKey: {
            eq: "templates/ProjectTemplate"
          }
        }
      }
      sort: {
        fields: [frontmatter___meta___homepageOrder]
        order: ASC
      }
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          client
          projectTitle
          description
          thumbnail {
            square
            urlSquare
            fullWidth
            urlFullWidth
            isGIF
          }
          meta {
            comingSoon
            isFullWidthOnHomepage
          }
        }
      }
    }
  }
`
