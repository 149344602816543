import React, { useEffect, useState } from 'react'

import Figure from 'Figure'
import Image from 'Image'
import LazyVideo from 'LazyVideo'

import useWindowSize from 'hooks/useWindowSize'

const ProjectPreviewImage = ({ square, landscape, urlSquare, urlLandscape, isGIF, isFullWidthOnHomepage, title, children }) => {
  const [image, setImage] = useState({ src: square, url: urlSquare })
  const { windowWidth } = useWindowSize()

  /**
   * Image dependent on window width and whether
   * or not it's been provided.
   */
  useEffect(() => {
    const shouldShowLandscapeImage = () => {
      return isFullWidthOnHomepage && landscape && windowWidth > 768
    }

    if (shouldShowLandscapeImage()) {
      setImage({ src: landscape, url: urlLandscape })
    } else {
      setImage({ src: square, url: urlSquare })
    }
    // eslint-disable-next-line
  }, [windowWidth])

  if (image.url) {
    /**
     * Create a poster image using the Image components
     * static makeUrl method.
     */
    const poster = Image.makeUrl({
      src: image.src,
      width: 200,
      quality: 'lightest',
      blur: 100
    })

    return (
      <figure className='c-project-roll-grid-item__img u-img-full u-mar-b-none@md u-pos-r'>
        <LazyVideo
          src={image.url}
          poster={poster}
          className='c-lazy-image__main--gallery-video'
          title={title}
        />
        {children}
      </figure>
    )
  }

  if (isGIF) {
    const previewWidth = 100

    const previewUrl = Image.makeUrl({
      src: image.src,
      width: previewWidth,
      dpr: 1,
      quality: 'lightest',
      blur: 100
    })

    return (
      <figure className='c-project-roll-grid-item__img u-img-full u-mar-b-none@md u-pos-r'>
        <div className='c-lazy-image'>
          <img
            data-src={image.src}
            alt={title}
            className='c-lazy-image__main lazyload'
          />
          <img
            src={previewUrl}
            width={previewWidth}
            className='c-lazy-image__preview'
            alt={title ? `Preview: ${title}` : null}
          />
        </div>
        {children}
      </figure>
    )
  }

  return (
    <Figure
      image={image.src}
      className='c-project-roll-grid-item__img u-img-full u-mar-b-none@md u-pos-r'
      width={673}
      sizes={{
        default: '100vw',
        md: 'calc((100vw - 32px) * 0.5)',
        xl: '608px'
      }}
      alt={title}
    >
      {children}
    </Figure>
  )
}

export default ProjectPreviewImage
