import React from 'react'
import { graphql } from 'gatsby'
import AnchorLink from 'react-anchor-link-smooth-scroll'

import { HTMLContent } from 'Content'
import Slider from 'Slider'
import Reveal from 'Reveal'

/**
 * Declare custom properties for the slider
 * @see: https://idangero.us/swiper/api/#pagination
 */
const sliderProps = {
  slidesPerView: 1,
  containerClass: 'swiper-container c-hero-slider',
  pagination: {
    el: '.c-hero-slider-pagination.o-list-inline',
    clickable: true,
    renderBullet: (index, className) => `<span class='c-hero-slider-pagination__bullet ${className}'></span>`
  },
  autoplay: {
    delay: 3000,
    disableOnInteraction: false
  },
  loop: true,
  effect: 'fade',
  parallax: true
}

const HomepageHero = ({ posts, footerText, lazyload }) => {
  /* Format the nodes (posts) */
  const slides = posts.map(({ client, projectTitle, featureImage, slug }) => (
    {
      title: projectTitle,
      sub: client,
      images: featureImage,
      slug
    }
  ))

  return (
    <div className='c-hero'>
      <Reveal ssrReveal>
        <div className='c-hero-main'>
          <Slider
            itemClassName='c-hero-slider-item'
            data={slides}
            lazyload={false}
            {...sliderProps}
          />
        </div>
      </Reveal>
      <div className='c-hero-footer'>
        <div className='c-hero-footer__content'>
          <AnchorLink
            href='#content'
            className='u-color-grey'
          >
            <HTMLContent content={footerText} />
          </AnchorLink>
        </div>
      </div>
    </div>
  )
}

export default HomepageHero

export const query = graphql`
  fragment FeaturedProjectsFragment on Query {
    featuredProjects: allMarkdownRemark(
      filter: {
        frontmatter: {
          draft: {
            eq: false
          }
          templateKey: {
            eq: "templates/ProjectTemplate"
          }
          meta: {
            isFeatured: {
              eq: true
            }
          }
        }
      }
      sort: {
        fields: [frontmatter___meta___homepageSliderOrder]
        order: ASC
      }
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          client
          projectTitle
          featureImage {
            mobile
            desktop
            url
            isGIF
          }
        }
      }
    }
  }
`
