import { useState, useLayoutEffect } from 'react'

const useWindowSize = () => {
  const [windowHeight, setWindowHeight] = useState(null)
  const [windowWidth, setWindowWidth] = useState(null)

  useLayoutEffect(() => {
    // window.addEventListener('resize', (e) => {
    //   console.log('resize')
    //   setWindowHeight(window.innerHeight)
    //   setWindowWidth(window.innerWidth)
    // })
    window.addEventListener('orientationchange', (e) => {
      setWindowHeight(window.innerHeight)
      setWindowWidth(window.innerWidth)
    })

    setWindowHeight(window.innerHeight)
    setWindowWidth(window.innerWidth)

    return () => window.removeEventListener('orientationchange', (e) => {
      setWindowHeight(window.innerHeight)
      setWindowWidth(window.innerWidth)
    })
  }, [])

  return { windowHeight, windowWidth }
}

export default useWindowSize
